export default {
  state:{
    receAddr:[]
  },
  mutations:{
    setReceAddr(state,data){
      state.receAddr = data;
    }
  },
  actions:{
    setReceAddr({commit},data){
      commit('setReceAddr',data)
    }
  },
  getters:{
    getReceAddr(state){
      return state.receAddr;
    }
  }
}